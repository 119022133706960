import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.className
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileRecurrenceDropdown = props => {
	const className = props.className || "paralian-theme-level-1-flipped"
	const onChange = props.onChange ? props.onChange : () => {}
	// TODO: use enumerators here
	const list = [
		{value: "DAYS", label: translate(DICTIONARY.DAYS.X)},
		{value: "WEEKS", label: translate(DICTIONARY.WEEKS.X)},
		{value: "MONTHS", label: translate(DICTIONARY.MONTHS.X)},
		{value: "YEARS", label: translate(DICTIONARY.YEARS.X)}
	]
	const defaultValue = props.defaultValue || 1
	const defaultIndex = list.findIndex(item => item.value === defaultValue)
	const defaultItem = list[defaultIndex < 4 && defaultIndex > -1 ? defaultIndex : 0]
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}`} className={className} size={"1"} showLabel={false}
		label={translate(DICTIONARY.DATE.X)} multiple={false} dataId={defaultItem.value}
		name={"date"} form={props.form} autoFocus={false} required={true}
		list={list} left={props.left}	defaultValue={defaultValue}
		bottomRadius={props.bottomRadius} dataA={defaultValue} onChange={() => {
		const element = document.getElementById(`${props.id}-input`)
		element.setAttribute("data-a", element.value)
		onChange()
	}}/>

}

export default MobileRecurrenceDropdown