import React, {useEffect, useState} from "react"
// import {MONTH, RECURRENCE, WEEKDAY} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import Modal from "../../../../../globals/components/Modal"
import MobileOrdinalsDropdown from "../../../../shared/input/dropdown/MobileOrdinalsDropdown"
import MobileDaysDropdown from "../../../../shared/input/dropdown/MobileDaysDropdown"
import MobileMonthDropdown from "../../../../shared/input/dropdown/MobileMonthDropdown"
import MobileNumberRangeDropdown from "../../../../shared/input/dropdown/MobileNumberRangeDropdown"
import MobileRecurrenceDropdown from "../../../../shared/input/dropdown/MobileRecurrenceDropdown"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {boolean} props.showRecurrenceModal
 * @param {function} props.setShowRecurrenceModal
 * @param {Object} props.recurrence
 * @param {'DAILY'|'WEEKLY'|'MONTHLY'|'YEARLY'|'ONCE'} props.recurrence.recurrence
 * @param {number} props.recurrence.frequency
 * @param {'DAY','DATE'} props.recurrence.day
 * @param {'JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER'} props.recurrence.month
 * @param {'MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY','SUNDAY'} props.recurrence.weekday
 * @param {Array<'MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY','SUNDAY'>} props.recurrence.weekdays
 * @param {number} props.recurrence.date
 * @param {boolean} props.recurrence.forceAutoBill
 * @param {boolean} props.recurrence.autoBill
 * @param {string} props.recurrence.activated - timestamp
 * @param {string} props.recurrence.expires - timestamp
 * @param {number} props.recurrence.termQuantity
 * @param {'DAYS','WEEKS','MONTHS','YEARS'} props.recurrence.termUnits
 * @param {boolean} props.recurrence.allowAutoRenew
 * @param {boolean} props.recurrence.autoRenew
 * @param {number} props.recurrence.renewalPremium
 * @param {boolean} props.recurrence.excludeWeekends
 * @param {boolean} props.recurrence.excludeWeekdays
 * @param {boolean} props.recurrence.prepaid
 * @param {boolean} props.recurrence.lockPrice
 * @return {JSX.Element}
 * @constructor
 */
const RecurrenceModal = props => {
	const [recurrence, setRecurrence] = useState(props.recurrence.recurrence)
	const [frequency, setFrequency] = useState(props.recurrence.frequency)
	const [month, setMonth] = useState(props.recurrence.month)
	const [weekday, setWeekday] = useState(props.recurrence.weekday)
	const [date, setDate] = useState(props.recurrence.date)
	const [stop, setStop] = useState(11)
	const [showOrdinals, setShowOrdinals] = useState(false)
	const [showDays, setShowDays] = useState(false)
	const [showMonths, setShowMonths] = useState(false)
	useEffect(() => {setRecurrence(props.recurrence.recurrence)}, [props.recurrence.recurrence])
	useEffect(() => {setFrequency(props.recurrence.frequency)}, [props.recurrence.frequency])
	useEffect(() => {setMonth(props.recurrence.month)}, [props.recurrence.month])
	useEffect(() => {setWeekday(props.recurrence.weekday)}, [props.recurrence.weekday])
	useEffect(() => {setDate(props.recurrence.date)}, [props.recurrence.date])
	switch (recurrence) {
		case "DAYS":
			setStop(6)
			setShowOrdinals(false)
			setShowDays(false)
			setShowMonths(false)
			break
		case "WEEKS":
			setStop(6)
			setShowOrdinals(false)
			setShowDays(true)
			setShowMonths(false)
			break
		case "MONTHS":
			setStop(11)
			setShowOrdinals(true)
			setShowDays(true)
			setShowMonths(false)
			break
		case "YEARS":
			setStop(5)
			setShowOrdinals(true)
			setShowDays(true)
			setShowMonths(true)
			break
	}
	const className = "paralian-theme-level-1-flipped-blue"
	return props.showRecurrenceModal ?
		<Modal title={translate(DICTIONARY.RECURRENCE.X)} padding={"0vh"}
			icon={ICONS.INFO_CIRCLE} onClick={() => props.setShowRecurrenceModal(false)} id={`order-${props.id}-recurrence`}
			body={<div>
				<div style={{padding: "2vh", margin: "1vh 0vh", height: "6vh"}}>{translate(DICTIONARY.EVERY.X)}</div>
				<div style={{display: "flex", fontSize: "2.5vh", margin: "1vh 0vh"}}>
					{/* 1-6 for Weeks, 1-12 for Months, 1-5 for Years */}
					<div style={{minWidth: "45%"}} onClick={() => {}}>
						<MobileNumberRangeDropdown start={1} stop={stop} step={1} id={`${props.id}-recurrence-frequency`}
							form={props.form} showLabel={false}	defaultValue={frequency} className={className}
							onChange={f => {setFrequency(f)}}/>
					</div>
					{/* Weeks, Months, Years */}
					<div style={{marginLeft: "1vh", width: "100%"}} onClick={() => {}}>
						<MobileRecurrenceDropdown id={`${props.id}-recurrence-recurrence`} form={props.form} showLabel={false}
							defaultValue={month} className={className} onChange={r => {setRecurrence(r)}}/>
					</div>
				</div>
				{/* ON */}
				<div style={{margin: "1vh 0vh", fontWeight: "bold"}}>{`- ${translate(DICTIONARY.OF.X).toUpperCase()} -`}</div>
				<div style={{display: "flex", fontSize: "2.5vh", margin: "1vh 0vh"}}>
					{/* MONDAYS, TUESDAYS, WEDNESDAYS, THURSDAYS, FRIDAYS, SATURDAYS, SUNDAYS for Weeks */}
					{/* THE 1st, 2nd, 3rd, 4th, Last, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY for Months */}
					{/* THE 1st, 2nd, 3rd, 4th, Last, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY of
					 JANUARY, FEBRUARY, MARCH, APRIL, MAY, JUNE, JULY, AUGUST, SEPTEMBER, OCTOBER, NOVEMBER, DECEMBER
					 for Months for Years */}
					{showOrdinals ? <div style={{minWidth: "45%"}} onClick={() => {}}>
						<MobileOrdinalsDropdown id={`${props.id}-recurrence-date`} form={props.form} defaultValue={date}
							className={className} showLabel={false}/>
					</div> : <></>}
					{showDays ? <div style={{marginLeft: "1vh", width: "100%"}} onClick={() => {}}>
						<MobileDaysDropdown id={`${props.id}-recurrence-weekday`} form={props.form} defaultValue={weekday}
							className={className} showLabel={false}/>
					</div> : <></>}
				</div>
				{showMonths ? <div style={{display: "flex", fontSize: "2.5vh", margin: "1vh 0vh"}}>
					<div style={{minWidth: "45%", height: "6vh", padding: "1vh"}}>{`- ${translate(DICTIONARY.OF.X).toUpperCase()} -`}</div>
					<div style={{marginLeft: "1vh", width: "100%"}} onClick={() => {}}>
						<MobileMonthDropdown id={`${props.id}-recurrence-month`} form={props.form} defaultValue={month}
							className={className} showLabel={false}/>
					</div>
				</div> : <></>}
			</div>}/> : <></>
}

export default RecurrenceModal