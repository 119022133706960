import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {RECURRENCE, WEEKDAY, MONTH} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileInputText from "../../shared/input/text/MobileInputText"
import MobileMonthDropdown from "../../shared/input/dropdown/MobileMonthDropdown"
import MobileDatesDropdown from "../../shared/input/dropdown/MobileDatesDropdown"
import MobileOrdinalsDropdown from "../../shared/input/dropdown/MobileOrdinalsDropdown"
import MobileDaysDropdown from "../../shared/input/dropdown/MobileDaysDropdown"
/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {Holiday} props.holiday
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputNewHoliday = props => {
	const [useDate, setUseDate] = useState(true)
	const className = props.className || "paralian-theme-level-1-flipped"
	const dateClassName = useDate ? className : "paralian-theme-level-1-flipped-blue"
	const dayClassName = useDate ? "paralian-theme-level-1-flipped-blue" : className
	const setRecurrence = recurrence => {
		try {
			document.getElementById(`${props.id}-recurrence`).setAttribute("data-recurrence", recurrence)
		} catch (e) {
			console.log(e)
		}
	}
	const markRecurrence = recurrence => {
		if (recurrence === RECURRENCE.DATE && !useDate) {
			setRecurrence(recurrence)
			setUseDate(true)
		} else if (recurrence === RECURRENCE.DAY && useDate) {
			setRecurrence(recurrence)
			setUseDate(false)
		}
	}
	useEffect(() => {
		if (props.holiday) {
			if (props.holiday.recurrence === RECURRENCE.DAY) {
				setUseDate(false)
			}
		}
	}, [props.holiday])
	let recurrence = RECURRENCE.DATE, name = null, month = MONTH.JANUARY, date = 1, weekday = WEEKDAY.MONDAY
	if (props.holiday) {
		recurrence = props.holiday.recurrence || RECURRENCE.DATE
		name = props.holiday.name
		date = props.holiday.date
		month = props.holiday.month
		weekday = props.holiday.weekday
	}
	return <div className={"w3-animate-opacity"}>
		<input id={`${props.id}-recurrence`} style={{display: "none"}} type={"text"} name={"state"} data-recurrence={recurrence}/>
		<div style={{margin: "1vh 0vh"}}>
			<MobileInputText id={`${props.id}-name`} form={props.form} showLabel={false} maxLength={"45"} required={true}
				name={"name"} placeholder={translate(DICTIONARY.DISPLAY_NAME.X)} autoComplete={"off"} defaultValue={name}
				label={translate(DICTIONARY.DISPLAY_NAME.X)} className={className}/>
		</div>
		<div>
			<div style={{padding: "2vh", margin: "1vh 0vh", height: "6vh"}}>{translate(DICTIONARY.YEARLY_RECURRENCE.X)}</div>
			{/* DATE RECURRENCE */}
			<div style={{display: "flex", fontSize: "2.5vh", margin: "1vh 0vh"}}>
				<div style={{minWidth: "45%"}} onClick={() => {if (!useDate) {markRecurrence(RECURRENCE.DATE)}}}>
					<MobileDatesDropdown id={`${props.id}-date-date`} form={props.form} showLabel={false}
						defaultValue={date} className={dateClassName}/>
				</div>
				<div style={{marginLeft: "1vh", width: "100%"}} onClick={() => {if (!useDate) {markRecurrence(RECURRENCE.DATE)}}}>
					<MobileMonthDropdown id={`${props.id}-date-month`} form={props.form} showLabel={false}
						defaultValue={month} className={dateClassName}/>
				</div>
			</div>
			<div style={{margin: "1vh 0vh", fontWeight: "bold"}}>{`- ${translate(DICTIONARY.OR.X).toUpperCase()} -`}</div>
			{/* DAY RECURRENCE */}
			<div style={{display: "flex", fontSize: "2.5vh", margin: "1vh 0vh"}}>
				<div style={{minWidth: "45%"}} onClick={() => {if (useDate) {markRecurrence(RECURRENCE.DAY)}}}>
					<MobileOrdinalsDropdown id={`${props.id}-day-ordinal`} form={props.form} defaultValue={date}
						className={dayClassName} showLabel={false}/>
				</div>
				<div style={{marginLeft: "1vh", width: "100%"}} onClick={() => {if (useDate) {markRecurrence(RECURRENCE.DAY)}}}>
					<MobileDaysDropdown id={`${props.id}-day-day`} form={props.form} defaultValue={weekday}
						className={dayClassName} showLabel={false}/>
				</div>
			</div>
			<div style={{display: "flex", fontSize: "2.5vh", margin: "1vh 0vh"}}>
				<div style={{minWidth: "45%", height: "6vh", padding: "1vh"}}>{translate(DICTIONARY.OF.X)}</div>
				<div style={{marginLeft: "1vh", width: "100%"}} onClick={() => {if (useDate) {markRecurrence(RECURRENCE.DAY)}}}>
					<MobileMonthDropdown id={`${props.id}-day-month`} form={props.form} defaultValue={month}
						className={dayClassName} showLabel={false}/>
				</div>
			</div>
		</div>
	</div>
}

export default MobileInputNewHoliday