import React, {useState} from "react"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {function} props.setShowRecurrenceModal
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const MakeOrderRecurringButton = props => {
	return props.order.buttons.makeRecurring ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"makeRecurring"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.MAKE_RECURRING.X)}} left={{icon: ICONS.REPEAT}}	className={"paralian-theme-level-1"}
			 	padding={"1vh"} style={{width: "100%"}} disabled={false}
			 	onClick={() => {props.setShowRecurrenceModal(true)}}/>
		</div> : <></>
}

export default MakeOrderRecurringButton